import ErrorComponent from "../components/ErrorPage";
import type { NextPage } from "next";
import Layout from "../components/Layout";

const FourOhFour: NextPage = () => {
  return (
    <Layout>
      <ErrorComponent errorCode="404" />
    </Layout>
  );
};

export default FourOhFour;
